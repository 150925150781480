// @flow strict-local
import * as React from "react";

import { Button, Col, Input, Row, Typography } from "antd";
import { Suspense, useState } from "react";

import Cookies from "js-cookie";
import DesktopLoadingIndicator from "../common/DesktopLoadingIndicator";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { useTranslation } from "react-i18next";

const { Text, Link } = Typography;

const LoginPage = (): React.Node => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const isKnowlificSite = window.jsData.site_name == "Knowlific";

  return (
    <Row>
      <Col span={12} className="login-left-image">
        <img src="/static/public/img/login-left-image.jpg" />
      </Col>
      <Col span={12} className="p-a-40">
        <div className="login-right-container">
          {isKnowlificSite && (
            <Row>
              <Col className="text-right" span={24}>
                <span className="p-r-32">{t("login.no_account_question")}</span>
                <Link href="/signup/">{t("login.sign_up")}</Link>
              </Col>
            </Row>
          )}
          <Row className="m-t-24">
            <Col span={24}>
              <img src={window.jsData?.logo_url} width={130} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h1 className="m-b-12">{t("login.sign_in")}</h1>
              <h3 className="m-b-40">{t("login.sign_in_tagline")}</h3>
            </Col>
          </Row>
          <form method="post" action="/accounts/login/">
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value={Cookies.get("csrftoken")}
            />
            <input
              type="hidden"
              name="next"
              value={window?.jsData?.next ?? ""}
            />
            <Row>
              <Col span={24}>
                <Text type="secondary">{t("login.username")}</Text>
                <br />
                <Input
                  className="m-t-8"
                  type="text"
                  size="large"
                  name="username"
                  autoComplete="email"
                  value={email}
                  onChange={(ev) =>
                    setEmail(ev.target?.value?.replace(" ", "") ?? "")
                  }
                />
              </Col>
            </Row>
            <Row className="m-t-24">
              <Col span={24}>
                <Text type="secondary">{t("login.password")}</Text>
                <br />
                <Input
                  className="m-t-8"
                  type={showPassword ? "text" : "password"}
                  size="large"
                  name="password"
                  autoComplete="password"
                  addonAfter={
                    showPassword ? (
                      <EyeInvisibleOutlined
                        className="m-t-4 clickable"
                        set="light"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <EyeOutlined
                        className="m-t-4 clickable"
                        set="light"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )
                  }
                />
              </Col>
            </Row>
            {Boolean(window.login_errors) &&
            (window.login_errors.__all__?.length ?? 0) > 0
              ? window.login_errors.__all__.map((err) => {
                  return (
                    <Row key="err.message">
                      <Col span={24}>
                        <br />
                        <Text className="p-t-8 d-inline-block" type="danger">
                          {err.message}
                        </Text>
                      </Col>
                    </Row>
                  );
                })
              : null}
            <Row className="m-t-24">
              <Col offset={12} span={12} className="text-right">
                <Link href="/accounts/password/reset/">
                  {t("login.forgot_password")}
                </Link>
              </Col>
            </Row>
            <Row className="m-t-40">
              <Col span={24}>
                <Button
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  size="large"
                  className="width-100"
                >
                  {t("login.sign_in")}
                </Button>
              </Col>
            </Row>
            <Row className="m-t-24 text-center">
              <Col span={24}>{t("or")}</Col>
            </Row>
            <Row className="m-t-24">
              <Col span={24}>
                <Button
                  href="/one_time_login/"
                  type="ghost"
                  shape="round"
                  size="large"
                  className="width-100"
                >
                  {t("login.one_time_login_send")}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
